<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HYXW' }">会议新闻</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/HY_list' }">重要会议</el-breadcrumb-item>
      <el-breadcrumb-item>会议详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="wrap">
      <div class="h1">
        国家放射影像专业质控中心专题讲座及<br />
        2024年国家级放射影像专业质控中心专家委员会第一次工作会议成功举办
      </div>
      <section>
        <div class="div_p">
          2024年3月29日下午，由国家放射影像专业质量控制中心主办，在重庆市悦来国际会议中心召开国家质控专题讲座及2024年国家级放射影像专业质控中心专家委员会第一次工作会议。
        </div>
        <div class="div_p">
          国家级放射影像专业质控中心主任委员金征宇教授、重庆市卫健委医政医管处张莉副处长为国家质控专场会议致辞。金征宇主委提到国家级放射影像专业质控中心成立时间不长，4年前正式挂牌成立，在逐渐学习完善。质量控制是高质量发展的基石，放射影像专业质控中心拟出台适应中国国情的统一质控方案，今年将在卫健委的支持下开展哨点医院的实地检查，希望真正为老百姓做好医疗服务工作。张莉副处长提到，受张维斌书记、邢雅翕主任、代茂利处长的委托，诚挚的感谢及热烈欢迎各地影像质控中心的专家共聚山水之城，魅力重庆。会议聚集影像质量质控的前沿技术及实践经验，在为重庆提供宝贵学习及交流平台的同时，也必将是相关领域的高端知识分享及思维碰撞，也必将推动全国的影像质量控制水平的提升。2024年3月5日，李强总理在政府工作报告中指出“深化公立医院改革，以患者为中心，改善医疗服务，推动检查检验结果互认”，在全国引起热议。当前重庆市卫健委正在按照重庆市委提出的数字重庆1361整体构架，以深化医改及服务民生为主线，聚焦群众急难愁盼的问题，开展一系列的影像检查互认的工作。在这一过程中，重庆市的医疗影像质控中心起了极大的作用，郭大静教授带领全市的影像质控人，负重前行，做了很多开拓性的工作。截止2023年底“医检互认”在全市的1151家公立医疗机构使用，下一步重庆市卫生健康委将依托于川渝卫生健康一体化发展及中央组织部组团式援藏的工作项目，实现线上跨省的检查检验的互认。昨天的成绩已经成为明天发展的助力和基石。希望广大同道始终保持敬畏之心，坚守质量安全底线，积极推进影像质量控制的创新发展。
        </div>
        <div>
          <img src="@/assets/GJJ2024/1.jpg" alt="">
          <img src="@/assets/GJJ2024/2.jpg" alt="">
        </div>
        <div class="div_p">
          国家质控专题讲座的上半场由重庆医科大学附属第二医院郭大静教授及北京协和医院孙昊教授担任主持。
        </div>
        <div>
          <img src="@/assets/GJJ2024/3.jpg" alt="">
        </div>
        <div class="div_p">
          首先由南京医科大学附属明基医院李澄教授介绍《江苏省放射质控工作近年来回顾》，介绍了江苏省放射诊断质控中心的简要历史，质控中心近期工作，并且提出了对放射诊断专业质控工作的思考。
        </div>
        <div>
          <img src="@/assets/GJJ2024/4.jpg" alt="">
        </div>
        <div class="div_p">
          四川大学华西医院吕粟教授介绍认知障碍的MR规范评价，痴呆的常见分类及原因，华西医院放射科疾病导向的亚专业建设，并且展示了多模态影像技术创新中心的建设。通过对疾病的规范化扫描及诊断达到影像质量的规范化控制的目的。
        </div>
        <div>
          <img src="@/assets/GJJ2024/5.jpg" alt="">
        </div>
        <div class="div_p">
          郑州大学第一附属医院高剑波教授解读《胃癌影像学检查与诊断规范化流程专家共识》，该共识从检查方法选择、检查前处置规范及检查规范几个方面的内容，给出了专家推荐意见。
        </div>
        <div>
          <img src="@/assets/GJJ2024/6.jpg" alt="">
        </div>
        <div class="div_p">
          华中科技大学同济医学院附属协和医院向东桥教授解读《中国Stanford
          B型主动脉夹层影像诊断和介入治疗临床指南2023》，介绍了指南制定的背景及内容，针对国内专家共识或国外指南不足之处，对B型夹层的诊断流程及介入操作规范等进行详细介绍，并且补充了主动脉夹层影像诊断内容。
        </div>
        <div>
          <img src="@/assets/GJJ2024/7.jpg" alt="">
        </div>
        <div class="div_p">
          上海交通大学医学院附属瑞金医院严福华教授讲解《肝脏影像检查技术规范》，结合肝脏影像检查在全国范围内的调查问卷，重点介绍了肝脏MRI扫描的调研结果，指出规范的检查技术对精准诊断至关重要，肝脏MRI的前沿技术有待完善和深入研究。
        </div>
        <div>
          <img src="@/assets/GJJ2024/8.jpg" alt="">
        </div>
        <div class="div_p">
          讲座的下半场由山东省医学影像学研究所王光彬教授及四川大学华西医院胡娜教授担任主持。
        </div>
        <div>
          <img src="@/assets/GJJ2024/9.jpg" alt="">
          <img src="@/assets/GJJ2024/10.jpg" alt="">
        </div>
        <div class="div_p">
          首先由北京协和医院王红燕教授讲解《信息化技术助力超声医学质量控制新发展》，介绍了基于医学影像信息系统的质量控制软件、基于5G技术的远程质控管理、可视化的质量反馈面板以及人工智能技术应用于超声影像的质量控制。
        </div>
        <div>
          <img src="@/assets/GJJ2024/11.jpg" alt="">
        </div>
        <div class="div_p">
          接下来由中南大学湘雅二医院刘军教授讲授《碘对比剂不良反应的预防与处理》。刘军教授介绍了医技护团队建立的过程，并且讲解了对比剂不良反应分类、对比剂不良反应应对准备、对比剂外渗预防及处理，提供了宝贵的临床工作经验。
        </div>
        <div>
          <img src="@/assets/GJJ2024/12.jpg" alt="">
        </div>
        <div class="div_p">
          贵州医科大学附属医院高波教授介绍《基于病例的影像质控经验分享》。基于病例的影像质控培训，是一种对放射质控模式的全新探索，是一种全过程、多环节的质控模式。此外高波教授提到贵州省建成了全省医学检验检查结果共享交换信息平台，使得影像检查互认工作向前大大推进。并且中国医学科学院北京协和医院与贵州省卫生健康委员会建设区域医学中心落地协议于2023年5月签订，其中医疗帮扶的重点内容就是质控中心的工作，未来贵州省的影像质控工作将在国家级影像放射专业质控中心的指导下更好的向前推进。
        </div>
        <div>
          <img src="@/assets/GJJ2024/13.jpg" alt="">
        </div>
        <div class="div_p">
          安徽医科大学第一附属医院李小虎教授介绍了《基于影像云人工智能质控的初步体会》。安徽省影像云平台基于大数据、图像处理、医学认知智能与推理等技术，提供了远程影像服务、数字影像服务、医学影像质控系统、影像人工智能等服务。有助于实现医学影像质控过程智能化、自动化和实时化，质控结果客观化。
        </div>
        <div>
          <img src="@/assets/GJJ2024/14.jpg" alt="">
        </div>
        <div class="div_p">
          随后，以线下及线上相结合的形式，召开2024年国家级放射影像专业质控中心专家委员会第一次工作会议（闭门会）。
        </div>
        <div class="div_p">
          国家级放射影像专业质控中心主任委员金征宇教授致辞。金征宇教授提到放射影像专业质控中心成立几年后，逐渐体会到质控的工作特点，作为国家政策的传递形式，质控中心应起到培训、监督及检查的作用。
        </div>
        <div>
          <img src="@/assets/GJJ2024/15.jpg" alt="">
        </div>
        <div class="div_p">
          北京协和医院副院长杜斌教授致辞。杜斌教授指出医学影像作为重要的辅助手段，已经成为现代医学的核心之一，对医学影像的质量、安全及效益进行监控及管理至为重要。国家卫健委于2020年9月委托北京协和医院开展全国放射影像质控工作，依托于北京协和医院的国家级放射科影像质控中心，在很短时间内搭建了从国家-省市-地市-县级的四级质控体系，组建了亚专业指标的专家工作组，形成了放射专业医疗及质量改进指标，获得了喜人的初步成果。这一过程离不开领导的大力支持及各位专家的大力协作及辛勤付出。本次大会将为质控工作的开展及交流提供良好的学习机会，预祝会议顺利举办，与会专家收获满满。
        </div>
        <div>
          <img src="@/assets/GJJ2024/16.jpg" alt="">
        </div>
        <div class="div_p">
          国家级放射影像专业质控中心副主任委员薛华丹教授宣读国家卫生健康委医政司医疗质量与评价处处长马旭东处长致辞。马旭东处长指出国家级放射影像专业质控中心专家委员会在金征宇主委的带领下，成立了一支有专业素养、质控经验和创新意识的专家队伍，初步建设了一个集讨论、研究、咨询、评估、监管和指导等职能于一体的综合性专业工作机构。在充分学习了解了质控中心任务、职责的前提下，从“加强基础质量安全管理，夯实结构质量；强化关键环节和行为管理，提高过程质量；织密质量管理网络，完善工作机制”三方面工作要求入手，切实强调规划引领、深入完善质控组织体系建设；紧盯目标任务、扎实推进指标体系工作；不断提升体系效能，探索新型工作机制模式。习近平总书记在党的二十大报告中指出要促进优质医疗资源扩容下沉和区域均衡布局，提高基层防病治病和健康管理能力。2024年里，特别希望国家放射影像质控中心能够更好地推进“破壁”工作，搭建省际间、学科间质控工作的交流；更扎实地做好“织网”工作，全国无死角，将医疗质控意识做进每一位放射影像工作者心中；更诚恳地“向死亡学习”，针对不良事件、危急值等关键重大医疗问题，能够细心、虚心、耐心的分析问题、讨论问题、解决问题。
        </div>
        <div>
          <img src="@/assets/GJJ2024/17.jpg" alt="">
        </div>
        <div class="div_p">
          国家级放射影像专业质控中心秘书孙昊教授进行国家级放射影像专业质控中心工作汇报。2023年国家级放射影像专业质控中心全面落实并完成“六个一”任务工作，开展危急值、单检查报告、肝脏放射影像3个调研工作，开发数字影像AI质控云方案并试点应用，成立亚专业质控专家组。并且建立国家质控哨点医院网络，完善系列例会制度；梳理目前NCIS系统内的六项影像质控指标，2023年的数据完整覆盖全国31个省，数据有效率大幅上涨，并且基于“急诊影像为先、医疗安全为重”的原则，上报2023年的放射影像专业质控指标。此外探索新型工作机制模式，提出抓两头促交流的四级质控、以指标为抓手的闭环质控、建共识搭内涵的标准质控以及变人工为智能的数据质控。2024年计划开展“CT增强率”等单病种/单检查调研工作，开发更多单病种/单检查的数字影像AI质控云方案并试点应用，进行放射影像亚专业指标调研工作，发布影像质控专家述评/单病种规范化影像诊断SCI论著。
        </div>
        <div>
          <img src="@/assets/GJJ2024/18.jpg" alt="">
        </div>
        <div class="div_p">
          国家级放射影像专业质控中心副主任委员薛华丹教授讲解放射影像专业医疗质量控制指标介绍。薛华丹教授介绍了2024年上报卫健委的7项质控指标的含义，包括：增强CT检查静脉对比剂外渗发生率、放射检查图像伪影发生率、放射影像危急值10分钟内通报完成率、急诊放射影像报告2小时内完成率、放射影像报告书写规范率、前列腺病变磁共振行前列腺影像报告和数据系统（PI-RADS）分类率以及乳腺钼靶进行影像报告和数据系统（BI-RADS）分类率。根据卫健委征求意见梳理，需要专家讨论的内容包括：放射检查图像伪影发生率、放射影像危急值10分钟内通报完成率、急诊放射影像报告2小时内完成率。
        </div>
        <div>
          <img src="@/assets/GJJ2024/19.jpg" alt="">
        </div>
        <div class="div_p">
          接下来，由国家级放射影像专业质控中心秘书于静教授宣读亚专业组工作制度。
        </div>
        <div>
          <img src="@/assets/GJJ2024/20.jpg" alt="">
        </div>
        <div class="div_p">
          在专家讨论的环节中，9位亚专业组组长均发表了自己的意见。
        </div>
        <div>
          <img src="@/assets/GJJ2024/21.jpg" alt="">
        </div>
        <div class="div_p">
          急诊组组长中国人民解放军东部战区总医院卢光明教授指出危急值指标工作意义重大，建议2024年重点完成10个指标，各单位再此基础上进行增量调整。对于急诊的报告时间，提出按照检查种类设置，对急诊DR、CT以及特殊CT检查的完成时间有所区分。对于危急值，除了考虑通报完成率外，还需要考查通报准确率，以及危急值通报时间和检查报告时间如何统一。
        </div>
        <div class="div_p">
          心胸组组长中国人民解放军海军军医大学第二附属医院刘士远教授建议对危急值指标含义进一步细化及量化，将放射检查图像伪影发生率与设备年限相结合，更好地指导临床工作。
        </div>
        <div class="div_p">
          MR组组长北京医院陈敏教授建议制定检查规范性、一致性、标准性，减少图像伪影率；同意放射影像危急值10分钟内通报完成率；急诊放射影像报告2小时完成率仅DR检查。
        </div>
        <div class="div_p">
          对比剂组组长广东省人民医院梁长虹教授发言提到建议对质控指标要简单明了易于执行，重点细化指标的含义。对于包含多项部位的影像检查，在计算阳性率时候，要避免重复计算。
        </div>
        <div class="div_p">
          头颈组组长同济大学附属同济医院王培军教授表明坚决贯彻落实亚专业组制度要求，借助质控指标体系的制定，完善工作规则和流程。
        </div>
        <div class="div_p">
          神经组组长北京大学人民医院洪楠教授发言提到，认真听取了孙昊教授的工作总结和薛华丹教授的质控指标讲解，国家级放射影像专业质控中心在过去一年中做了大量的工作，取得了喜人的成果，获得马旭东处长的高度评价。神经组将尽快开展2024年的工作任务。危急值里面涉及神经系统6项，建议质控中心提供培训视频模版及统一的背景，供各质控中心使用。
        </div>
        <div class="div_p">
          CT组组长四川大学华西医院宋彬教授发言提到，CT亚专业组未来将致力于工作的分工及落实，理清工作清单，建立工作台账，全力推进重点工作，利用各种机会推动质控指标落地。并且积极探索AI大模型在质控工作中的应用，推进报告规范，多做创新工作。
        </div>
        <div class="div_p">
          X线组组长吉林大学第一医院张惠茅教授代表X组表达了坚决做好相关工作的决心。并且提出几点建议，首先放射影像危急值10分钟内通报完成率的时间点有待进一步明确。去年在刘士远主委带领下做全国调研时候发现，全国的信息化差异化非常大，县级医院信息化有待完善。危急值通报时间点如何计算，是否有量化的方法，可以考虑依托信息化的平台，为质控指标落地助力。此外在危急值病种含义有待细化，可以考虑征求有代表性的下级医院的意见。
        </div>
        <div class="div_p">
          消化组组长上海交通大学医学院附属瑞金医院严福华教授指出三级医院及基层医院的病种差异比较大，例如主动脉分支的夹层，临床也希望上报，对预后有影响。急诊报告时间，瑞金医院DR检查一般半小时能完成报告，
          CTA时间比较长。今年抓紧完成的危急值中4个腹部指标的培训录像录制，建议细化危急值指标，使其具有良好的操作性。
        </div>
        <div class="div_p">
          此外，现场省质控中心负责人及与会专家也积极发言。
        </div>
        <div class="div_p">
          郑州大学第一附属医院高剑波教授建议质控指标要清晰明确。急诊报告2小时完成率，DR没有问题，CT可以部分实现。危急值10分钟完成率，口头报告可以完成，书面报告有难度。
        </div>
        <div class="div_p">
          山东省医学影像学研究所王光彬教授提出危急值指标将覆盖各级综合医院及专科医院，是否要考虑医院的病种差异。建议危急值报告以口头为主，保留书面记录，便于追溯查询。对危急值指标范围的界定，凡是危及患者生命的情况，均应上报。
        </div>
        <div class="div_p">
          中南大学湘雅二医院刘军教授建议危急值指标简单明了，急诊报告时间的规定不宜过长，CTA两个小时可以出口头报告。湖南省要求危急值口头报告、电话、网络直报，登记在册。
        </div>
        <div class="div_p">
          重庆医科大学附属第二医院郭大静教授提到了AI产品在急诊影像报告工作中的使用，实际工作中尽快出口头报告，然后再出书面报告。危急值指标数量建议逐步完善。
        </div>
        <div class="div_p">
          南京医学大学附属明基医院李澄教授提出经过江苏省质控中心专家讨论，对质控指标意见如下：1、建议对图像伪影分级，设置图像质量不良率指标，重点调查影响图像质量的伪影。2、对比剂外渗滤，涉及护理的质量控制，不益作为放射科质控指标。3、PI-RADS及BI-RADS指标，部分专科医院此类疾病较少，是否考虑指标的应用范围。
        </div>
        <div class="div_p">
          贵州医院大学附属医院高波教授指出全省300多家二级医院信息化普及程度弱，今后需要在工作中对质控指标进一步梳理，并且完善信息化建设。
        </div>
        <div class="div_p">
          会议的最后，国家放射影像专业质控中心主任委员金征宇教授在总结中提到，马旭东处长指出“向死亡学习”，影像检查如何在抢救患者中的发挥作用，如何把患者从死亡线上拽回来，是危急值指标及急诊报告指标制定时需要重点考量的内容。质控指标不要求100%完成，危急值必须涵盖可能导致患者死亡的指标。如果地区发展不平衡，诊疗水平有差异，质控中心应加强培训，持续改进。此外要考虑多学科协作，在救治患者的过程中，放射科要积极配合临床相关科室，为抢救患者赢得时间。质控指标分为普适指标及专病指标，今年的工作重点是普适指标，未来将进一步完善专病指标，也欢迎各质控中心积极提出意见并参与工作。
        </div>

      </section>

    </div>
  </div>
</template>

<script>
export default {
  name: "GJJ2024",

}
</script>

<style scoped>
.wrap {
  max-width: 677px;
  margin: 40px auto;
  color: rgb(0, 0, 0);
  background: #fff;
  padding: 10px;
  line-height: 2;
}

img {
  vertical-align: middle;
  max-width: 100%;
  width: 655px !important;
  box-sizing: border-box;
  height: auto !important;
  visibility: visible !important;
  margin-bottom: 10px;
}

.h1 {
  font-size: 22px;
  line-height: 1.4;
  margin-bottom: 14px;
  text-align: center;
}

.div_p {
  text-indent: 2.125em;
  white-space: normal;
  margin: 0px;
  padding: 10px;
  box-sizing: border-box;
  visibility: visible;
}

.div_tit {
  text-align: center;
}
</style>